






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Column extends Vue {
  @Prop()
  public readonly width!: number;

  @Prop()
  public readonly mdWidth!: number;

  @Prop()
  public readonly smWidth!: number;
}






















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Card extends Vue {
  @Prop({ default: null })
  private readonly image!: string;

  @Prop({ default: '0.75' })
  private readonly imageAr!: string;

  @Prop()
  private readonly href!: string;

  @Prop()
  private readonly isExternal!: boolean;

  mounted () {
    console.log(this.image)
  }

  handleClicked () {
    if (this.isExternal) {
      window.open(this.href, '_blank')
    } else {
      this.$router.push(this.href)
    }
  }
}

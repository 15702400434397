import Vue from 'vue'
import Vuex from 'vuex'
import { ENVModule, ENVState } from './modules/ENVModule'
import { IdentityModule, IdentityState } from './modules/IdentityModule'
import { UIModule, UIState } from './modules/UIModule'

Vue.use(Vuex)

interface State {
  ui: UIState;
  env: ENVState;
  identity: IdentityState;
}

export default new Vuex.Store({
  modules: {
    ui: new UIModule(),
    env: new ENVModule(),
    identity: new IdentityModule()
  },
  state: {} as State
})




















import { DateModel } from 'truemarket-modules/src/models/api/common'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import FileUploadField from './FileUploadField.vue'

@Component({
  components: {
    FileUploadField
  }
})
export default class Field extends Vue {
  @Prop({ default: 'text' })
  readonly type!: string;

  @Prop()
  readonly placeholder!: string;

  @Prop({ required: true })
  readonly label!: string;

  @Prop({ default: false })
  private readonly required!: boolean;

  @Prop()
  readonly autocomplete!: string;

  @Prop({ default: '' })
  private readonly value!: string;

  @Prop({ default: false })
  readonly readonly!: boolean;

  val: any = '';

  empty = false;

  mounted () {
    this.valueUpdated()
  }

  @Watch('value')
  valueUpdated () {
    this.empty = false

    if (this.type === 'date') {
      this.val = (this.value as unknown as DateModel).HtmlFormattedDate
    } else if (typeof this.value === 'string') {
      this.val = this.value
    } else if (this.value !== null && this.value !== undefined) {
      this.val = (this.value as any).toString()
    } else if (this.required === true) {
      if (this.value === null || this.value === undefined) {
        this.empty = true
      }
    }
  }

  handleInput (v: string) {
    if (this.type === 'userfile') {
      this.$emit('input', v)
      return
    }

    const val: string | null = (this.$refs.input as HTMLInputElement).value

    if (this.type === 'date') {
      Services.API.Dates.GetDate(val).then((date) => {
        this.val = date.HtmlFormattedDate ? date.HtmlFormattedDate : date
        this.$emit('input', date)
        // eslint-disable-next-line
      }).catch(e => {
        this.val = ''
        this.$emit('input', '')
      })
      return
    }

    this.val = val

    if (val === '') {
      this.$emit('input', null)
    } else {
      this.$emit('input', val)
    }
  }
}

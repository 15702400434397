


























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
  @Prop()
  private readonly active!: boolean;

  @Prop()
  private readonly title!: string;

  @Prop({ default: 'subtle' })
  private readonly type!: string;

  @Prop()
  private readonly narrow!: boolean;

  @Prop()
  private readonly center!: boolean;

  @Prop()
  private readonly loading!: boolean;
}








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class NavigationItemBadge extends Vue {
  @Prop()
  private readonly badge!: string;

  @Prop({ default: 'primary' })
  private readonly type!: string;
}























import { FormDataset, FormField, FormValue } from '@/app_code/Forms'
import { UIError } from 'truemarket-modules/src/models/ui'
import { HttpError } from 'truemarket-modules/src/services'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import RichTextField from './RichTextField.vue'

@Component({
  components: {
    RichTextField
  }
})
export default class Form extends Vue {
  @Prop()
  public readonly validationErrors!: boolean;

  @Prop({ default: () => [] })
  public readonly fields!: FormField[];

  private fieldValues: FormValue[] = [];

  ErrorState: Record<string, string[]> | null = null;
  Errors: UIError[] = [];

  public GetValues (): FormDataset {
    return new FormDataset({
      Fields: this.fieldValues
    })
  }

  @Watch('fields')
  updateFieldValues () {
    this.fieldValues = this.fields.map((f) => ({ Key: f.Key, Value: f.Value ?? '' }))
  }

  mounted () {
    this.updateFieldValues()
  }

  handleFieldInput (field: FormField, value: string) {
    const f = this.fieldValues.find((fv) => fv.Key === field.Key)

    if (f) f.Value = value

    if (field.OnGotValue) field.OnGotValue()
  }

  public SetErrorState (error: HttpError, autoError = true) {
    if (autoError) this.Errors = []

    if (error.ModelState) {
      this.ErrorState = error.ModelState

      if (autoError) {
        this.Errors.push({
          Scheme: 'danger',
          Message: 'Please correct the errors highlighted below'
        })
      }
    }
  }
}

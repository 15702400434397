











import ModalOptions from '@/app_code/Modals/Options/ModalOptions'
import { Services } from 'truemarket-modules/src/services'
import { Vue, Component } from 'vue-property-decorator'
const BusinessBranchPickerModal = () => import('./BusinessBranchPickerModal.vue')
const ConfirmModal = () => import('./ConfirmModal.vue')
const ZonePickerModal = () => import('./ZonePickerModal.vue')
const ReportPickerModal = () => import('./ReportPickerModal.vue')
const CreateReportFromTemplateModal = () => import('./CreateReportFromTemplateModal.vue')

@Component({
  components: {
    BusinessBranchPickerModal,
    ConfirmModal,
    ZonePickerModal,
    ReportPickerModal,
    CreateReportFromTemplateModal
  }
})
export default class PredefinedModals extends Vue {
  closeModal (modal: ModalOptions) {
    Services.EventBus.Trigger('ui:predefinedModal:hide', modal.Type)
  }
}

import Vue from 'vue'

import ConsolePage from './ConsolePage.vue'
import AuthPage from './AuthPage.vue'
import ErrorPage from './ErrorPage.vue'
import PrintPage from './PrintPage.vue'

import AuthPageContent from './AuthPageContent.vue'
import ErrorPageDetails from './ErrorPageDetails.vue'

import Row from './Row.vue'
import Column from './Column.vue'

Vue.component('ConsolePage', ConsolePage)
Vue.component('AuthPage', AuthPage)
Vue.component('ErrorPage', ErrorPage)
Vue.component('PrintPage', PrintPage)

Vue.component('AuthPageContent', AuthPageContent)
Vue.component('ErrorPageDetails', ErrorPageDetails)

Vue.component('Row', Row)
Vue.component('Column', Column)

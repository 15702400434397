

















import { Component, Vue } from 'vue-property-decorator'
import Toolbar from '../UI/Toolbar/Toolbar.vue'

@Component({
  components: {
    Toolbar
  }
})
export default class ConsolePage extends Vue {

}

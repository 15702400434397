






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Cards extends Vue {
  @Prop({ default: 'var(--default-cards-per-row)' })
  private readonly perRow!: string;
}

import { BusinessBranchModel } from 'truemarket-modules/src/models/api/businesses'
import { LoggedInUserModel } from 'truemarket-modules/src/models/api/users'
import GetPracticeModel from '../../../../truemarket-modules/src/models/api/practices/GetPracticeModel'
import StateModule from './StateModule'

interface IdentityState {
  User: LoggedInUserModel | null;
  LoggedIn: boolean;
  IsAdmin: boolean;
  BusinessBranch: BusinessBranchModel | null;
  Practices: GetPracticeModel[] | null;
  SelectedPractice: GetPracticeModel | null;
}

class IdentityModule implements StateModule<IdentityState> {
  namespaced = true

  state = {
    User: null,
    LoggedIn: false,
    IsAdmin: false,
    BusinessBranch: null,
    Practices: null,
    SelectedPractice: null
  }

  mutations = {
    setLoggedIn (state: IdentityState, loggedIn: boolean) {
      state.LoggedIn = loggedIn
    },
    setUser (state: IdentityState, user: LoggedInUserModel | null) {
      state.User = user
      state.LoggedIn = user !== null
      if (user && (user.Roles?.includes('Webmaster') || user.Roles?.includes('Valuer'))) {
        state.IsAdmin = true
      } else {
        state.IsAdmin = false
      }
    },
    setBranch (state: IdentityState, businessBranch: BusinessBranchModel) {
      state.BusinessBranch = businessBranch
    },
    setPractices (state: IdentityState, practices: GetPracticeModel[]) {
      state.Practices = practices

      if (practices.length) {
        state.SelectedPractice = practices[0]
      }
    },
    setSelectedPractice (state: IdentityState, practice: GetPracticeModel) {
      state.SelectedPractice = practice
    }
  }
}

export { IdentityModule, IdentityState }

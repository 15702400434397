






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ToolbarItem extends Vue {
  @Prop()
  private readonly to!: string;

  @Prop({ required: true })
  private readonly type!: string;

  @Prop({ required: true })
  private readonly icon!: string;

  handleClicked () {
    if (this.type === 'btn') {
      this.$emit('click')
    }
  }
}

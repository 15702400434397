















import { Component, Vue, Prop } from 'vue-property-decorator'
import { DropdownListItem } from '../../../../app_code/Forms'
import { Util } from '../../../../app_code/Util'

@Component
export default class RadioButtons extends Vue {
  @Prop()
  private readonly items!: DropdownListItem[];

  @Prop({ default: 'primary' })
  private readonly type!: string;

  @Prop({ required: true })
  private readonly label!: string;

  private readonly group = Util.GetGuid();

  private selected = '';
}












import { Vue, Component } from 'vue-property-decorator'
import PredefinedModals from '../Modals/PredefinedModals.vue'

import DynamicModal from './Elements/DynamicModal.vue'

@Component({
  components: {
    DynamicModal,
    PredefinedModals
  }
})
export default class ModalStack extends Vue {
}














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Hero extends Vue {
  @Prop()
  private image!: string;

  @Prop()
  private heading!: string;

  @Prop()
  private subheading!: string;
}

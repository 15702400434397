
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Statistic extends Vue {
  @Prop({ required: true })
  private readonly name!: string;

  @Prop({ required: true })
  private readonly value!: string;

  @Prop({ default: 'activity' })
  private readonly icon!: string;

  @Prop({ default: 'info' })
  private readonly type!: string;
}

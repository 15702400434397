












import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Btn extends Vue {
  @Prop({ default: 'primary' })
  private readonly type!: string;

  @Prop({ default: false })
  private readonly invert!: boolean;

  @Prop({ default: false })
  private readonly loading!: boolean;

  @Prop()
  private readonly icon!: string;

  @Prop({ default: '0.75em 1.2em' })
  private readonly padding!: string;

  @Prop()
  private readonly bold!: boolean;

  @Prop()
  private readonly block!: boolean;

  @Prop({ default: 'n' })
  private readonly size!: string;

  handleClicked () {
    this.$emit('click')
  }
}

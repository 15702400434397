




















































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { DataTableDef, DataTableRowDef, DataTableColDef } from '../app_code/DataTable'
import { DropdownListItem } from '../app_code/Forms'

@Component
export default class Home extends Vue {
  private dtData: object | null = null;

  private modal1Active = false;
  private modal2Active = false;
  private modal3Active = false;

  private ddlItems = [
    new DropdownListItem('house', 'House', { type: 'component', value: 'house' }),
    new DropdownListItem('unit', 'Unit', { type: 'component', value: 'unit' }),
    new DropdownListItem('shed', 'shed', { type: 'component', value: 'shed' }),
    new DropdownListItem('office', 'Office', { type: 'component', value: 'office' })
  ]

  private rbItems = [
    new DropdownListItem('house', 'House', { type: 'component', value: 'house' }),
    new DropdownListItem('unit', 'Unit', { type: 'component', value: 'unit' }),
    new DropdownListItem('shed', 'shed', { type: 'component', value: 'shed' }),
    new DropdownListItem('office', 'Office', { type: 'component', value: 'office' })
  ]

  private dtDef: DataTableDef = new DataTableDef(new DataTableRowDef(ep => ep.id, [
    new DataTableColDef('Season', row => row.season),
    new DataTableColDef('Episode', row => row.number),
    new DataTableColDef('Name', row => row.name),
    new DataTableColDef('Air Date', row => row.airdate),
    new DataTableColDef('Type', row => row.type)
  ]))

  mounted () {
    // fetch('/assets/data/demo/shows.json').then(res => {
    //   res.json().then(data => {
    //     this.dtData = data
    //     console.log(this.dtData)
    //   })
    // })

    this.$router.replace('/dashboard/admin/valuations?statuses=10t')
  }

  closeModal2 () {
    this.$router.push('/account/login')
  }
}

import AppConfig from './config'

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { Bootstrapper } from 'truemarket-modules/src/startup'

import './components/Layout/global'
import './components/UI/global'
import { Services } from 'truemarket-modules/src/services'
import { Logger, LogLevel } from 'truemarket-modules/src/debug/Logger'

import ConfirmationService from 'primevue/confirmationservice'

Vue.config.productionTip = false
Vue.prototype.$valuationFromVersion = 'TM-Console-1.0'
Vue.use(ConfirmationService)

Bootstrapper.Initialise(AppConfig).then(() => {
  store.commit('ui/bootstrap')

  router.beforeEach((to, from, next) => {
    const token = Services.CredentialManager.GetToken()

    if (!token && !to.meta.allowAnon) {
      router.push('/account/login')
      return
    }

    next()
  })

  const mountApp = (success: boolean) => {
    new Vue({
      router: success ? router : undefined,
      store,
      render: h => h(App)
    }).$mount('#app')
  }

  if (Services.CredentialManager.GetToken()) {
    Services.API.Users.Me().then((user) => {
      store.commit('identity/setUser', user)

      Services.API.Users.GetPractices().then((practices) => {
        store.commit('identity/setPractices', practices)
      })

      mountApp(true)
    }).catch((e) => {
      Logger.Log(LogLevel.Err, 'main', 'Failed to get user', e)
      mountApp(true)

      if (e.Status === 403) {
        router.push('/account/login')
      }
    })
  } else {
    mountApp(true)
  }
  /*
  Services.API.Telemetry.Ping().then((success) => {
    store.commit('env/setErrored', !success)

    mountApp(success)
  }).catch((ex) => {
    Logger.Log(LogLevel.Critical, ex)

    store.commit('env/setErrored', true)

    mountApp(false)
  })
  */
})

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard Index',
    component: () => import('../views/dashboard/Base.vue'),
    children: [
      {
        path: 'admin/profile',
        component: () => import('../views/dashboard/admin/profile/ValuerProfileIndex.vue')
      },
      {
        path: 'admin/enumerations',
        component: () => import('../views/dashboard/admin/enumerations/Index.vue')
      },
      {
        path: 'admin/api_access',
        component: () => import('../views/dashboard/admin/api_access/Index.vue')
      },
      {
        path: 'admin/enumerations/:type',
        component: () => import('../views/dashboard/admin/enumerations/Edit.vue')
      },
      {
        path: 'admin/valuations',
        component: () => import('../views/dashboard/admin/valuations/Index.vue')
      },
      {
        path: 'admin/temp_valuations',
        component: () => import('../views/dashboard/admin/valuations/TempIndex.vue')
      },
      {
        path: 'admin/new_valuation',
        component: () => import('../views/dashboard/admin/valuations/New.vue')
      },
      {
        path: 'admin/enumerations/:type',
        component: () => import('../views/dashboard/admin/valuations/Edit.vue')
      },
      {
        path: 'admin/contacts',
        component: () => import('../views/dashboard/admin/contacts/Index.vue')
      },
      {
        path: 'admin/invoices',
        component: () => import('../views/dashboard/admin/invoices/Index.vue')
      },
      {
        path: 'admin/invoices/:id',
        component: () => import('../views/dashboard/admin/invoices/InvoiceDetails.vue')
      },
      {
        path: 'admin/countries',
        component: () => import('../views/dashboard/admin/countries/Index.vue')
      },
      {
        path: 'admin/countries/:id',
        component: () => import('../views/dashboard/admin/countries/Edit.vue')
      },
      {
        path: 'admin/zones/:id',
        component: () => import('../views/dashboard/admin/zones/Edit.vue')
      },
      {
        path: 'admin/franchises',
        component: () => import('../views/dashboard/admin/franchises/Index.vue')
      },
      {
        path: 'admin/franchises/:id',
        component: () => import('../views/dashboard/admin/franchises/Edit.vue')
      },
      {
        path: '',
        name: 'Dashboard',
        component: () => import('../views/dashboard/Index.vue')
      },
      {
        path: '/dashboard/profile',
        name: 'Profile',
        component: () => import('../views/dashboard/Profile.vue')
      },
      {
        path: 'branches/:branchId',
        name: 'Business Branch Profile',
        component: () => import('../views/dashboard/BusinessBranchDetails.vue')
      },
      {
        path: 'blogs',
        name: 'Blogs Index',
        component: () => import('../views/dashboard/blog/Index.vue')
      },
      {
        path: 'blogs/new',
        name: 'Create Blog',
        component: () => import('../views/dashboard/blog/CreatePost.vue')
      },
      {
        path: 'blogs/:id',
        name: 'Edit Blog',
        component: () => import('../views/dashboard/blog/EditPost.vue')
      },
      {
        path: 'admin/reports/:id',
        component: () => import('../views/dashboard/admin/reports/EditReport.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/admin/reports/print/:id',
    component: () => import('../views/print/PrintReport.vue')
  },
  {
    path: '/account/auth-check',
    name: 'AuthCheck',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/account/AuthCheck.vue')
  },
  {
    path: '/account/login',
    name: 'Login',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/account/Login.vue')
  },
  {
    path: '/account/forgot-password',
    name: 'Forgot Password',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/account/ForgotPassword.vue')
  },
  {
    path: '/account/reset-password',
    name: 'Reset Password',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/account/ResetPassword.vue')
  },
  {
    path: '/account/logout',
    name: 'Logout',
    component: () => import('../views/account/Logout.vue')
  },
  {
    path: '/account/register',
    name: 'Register',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/account/Register.vue')
  },
  {
    path: '/error/under-maintenance',
    name: 'Under Maintenance',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/error/Maintenance.vue')
  },
  {
    path: '/error/404',
    name: 'Not Found',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/error/NotFound.vue')
  },
  {
    path: '/error/500',
    name: 'Server Error',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/error/InternalServerError.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

export default router

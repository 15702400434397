import { Util } from '../Util'

class DataTableColDef {
  public readonly Label: string;
  public readonly Accessor: (rowData: any) => string;
  public readonly Id: string = Util.GetGuid();

  constructor (label: string, accessor: (rowData: any) => string) {
    this.Label = label
    this.Accessor = accessor
  }
}

export { DataTableColDef }

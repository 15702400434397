



























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class AuthPageContent extends Vue {
  @Prop()
  public loading!: boolean;

  @Prop()
  public readonly title!: string;

  get year () {
    return (new Date()).getFullYear()
  }
}







































import { Vue, Component } from 'vue-property-decorator'

import ToolbarItem from './ToolbarItem.vue'
import BusinessPicker from '../../Controls/BusinessPicker.vue'

@Component({
  components: {
    ToolbarItem,
    BusinessPicker
  }
})
export default class Toolbar extends Vue {
  businessPickerOpen = false

  setTheme (theme: string) {
    this.$store.commit('ui/setTheme', theme)
  }
}








import { Component, Vue, Prop } from 'vue-property-decorator'

import { DataTableRowDef } from '@/app_code/DataTable'

@Component
export default class DataRow extends Vue {
  @Prop({ required: true })
  private readonly key!: string;

  @Prop({ required: true })
  private readonly item!: any;

  @Prop({ required: true })
  private readonly model!: DataTableRowDef;
}












import { Component, Vue, Prop } from 'vue-property-decorator'
import RichTextEditor from '../../../Controls/RichTextEditor.vue'

@Component({
  components: {
    RichTextEditor
  }
})
export default class RichTextField extends Vue {
  @Prop({ default: '' })
  private readonly value!: string;

  @Prop()
  private readonly label!: string;

  handleInput (val: string | undefined) {
    this.$emit('input', val)
  }
}

import Vue from 'vue'

import Navigation from './Navigation/Navigation.vue'
import NavigationGroup from './Navigation/NavigationGroup.vue'
import NavigationItem from './Navigation/NavigationItem.vue'

import Icon from './Icon.vue'

import FS from './Util/FS.vue'

import Card from './Elements/Card.vue'
import CardFlag from './Elements/CardFlag.vue'
import Cards from './Elements/Cards.vue'
import ContentSection from './Elements/ContentSection.vue'
import LazyImage from './Elements/LazyImage.vue'
import Heading from './Elements/Heading.vue'
import Statistic from './Elements/Statistic.vue'
import Alert from './Elements/Alert.vue'
import Btn from './Elements/Btn.vue'
import LinkBtn from './Elements/LinkBtn.vue'
import BtnGroup from './Elements/BtnGroup.vue'
import Spinner from './Elements/Spinner.vue'
import Hero from './Elements/Hero.vue'

import DataTable from './Elements/DataTable/DataTable.vue'

import '@/assets/css/forms.css'
import Form from './Elements/Forms/Form.vue'
import Field from './Elements/Forms/Field.vue'
import DropdownList from './Elements/Forms/DropdownList.vue'
import Checkbox from './Elements/Forms/Checkbox.vue'
import ToggleSwitch from './Elements/Forms/ToggleSwitch.vue'
import RadioButtons from './Elements/Forms/RadioButtons.vue'

import Avatar from './Elements/Avatar.vue'

import Modal from './Elements/Modal.vue'

import PrimeVue from 'primevue/config'
import PDataTable from 'primevue/datatable'
import PColumn from 'primevue/column'
import PInputText from 'primevue/inputtext'
import PInputNumber from 'primevue/inputnumber'
import PButton from 'primevue/button'
import PCalendar from 'primevue/calendar'
import PDropdown from 'primevue/dropdown'
import PMultiSelect from 'primevue/multiselect'

Vue.use(PrimeVue)
Vue.component('PDataTable', PDataTable)
Vue.component('PColumn', PColumn)
Vue.component('PInputText', PInputText)
Vue.component('PInputNumber', PInputNumber)
Vue.component('PButton', PButton)
Vue.component('PCalendar', PCalendar)
Vue.component('PDropdown', PDropdown)
Vue.component('PMultiSelect', PMultiSelect)

Vue.component('Navigation', Navigation)
Vue.component('NavigationGroup', NavigationGroup)
Vue.component('NavigationItem', NavigationItem)

Vue.component('Icon', Icon)

Vue.component('FS', FS)

Vue.component('Card', Card)
Vue.component('CardFlag', CardFlag)
Vue.component('Cards', Cards)
Vue.component('ContentSection', ContentSection)
Vue.component('LazyImage', LazyImage)
Vue.component('Heading', Heading)
Vue.component('Statistic', Statistic)
Vue.component('Alert', Alert)
Vue.component('Btn', Btn)
Vue.component('LinkBtn', LinkBtn)
Vue.component('BtnGroup', BtnGroup)
Vue.component('Spinner', Spinner)
Vue.component('Hero', Hero)

Vue.component('DataTable', DataTable)

Vue.component('Form', Form)
Vue.component('Field', Field)
Vue.component('DropdownList', DropdownList)
Vue.component('Checkbox', Checkbox)
Vue.component('ToggleSwitch', ToggleSwitch)
Vue.component('RadioButtons', RadioButtons)

Vue.component('Avatar', Avatar)

Vue.component('Modal', Modal)

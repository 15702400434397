class DropdownListItem {
  public readonly Key: string;
  public readonly Label: string;
  public readonly Value?: object | string;

  constructor (key: string, label: string, value?: object | string) {
    this.Key = key
    this.Label = label
    this.Value = value
  }
}

export { DropdownListItem }















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class ToggleSwitch extends Vue {
  @Prop({ required: true })
  private readonly value!: boolean;

  private isChecked = false;

  @Prop({ required: true })
  private readonly label!: string;

  @Prop({ default: 'primary' })
  private readonly type!: string;

  @Watch('value')
  handleValChanged () {
    this.isChecked = this.value
  }

  handleUpdated () {
    const cb = this.$refs.cb as HTMLInputElement

    this.isChecked = cb.checked

    this.$emit('input', cb.checked)
  }
}

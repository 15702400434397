import { Modal } from '@/app_code/Modals/Modal'
import ModalOptions from '@/app_code/Modals/Options/ModalOptions'
import { Services } from 'truemarket-modules/src/services'
import StateModule from './StateModule'

interface UIState {
  theme: 'light' | 'dark';
  navOpen: boolean;
  loading: boolean;
  modals: Modal[];
  predefModals: ModalOptions[];
}

class UIModule implements StateModule<UIState> {
  namespaced = true;

  state: UIState = {
    theme: 'light',
    navOpen: false,
    loading: false,
    modals: [],
    predefModals: []
  }

  mutations = {
    setTheme (state: UIState, theme: 'light' | 'dark'): void {
      state.theme = theme
    },
    toggleNav (state: UIState): void {
      state.navOpen = !state.navOpen
    },
    setLoading (state: UIState, loading: boolean): void {
      state.loading = loading
    },
    showModal (state: UIState, modal: Modal): void {
      state.modals.push(modal)
    },
    showPredefModal (state: UIState, options: ModalOptions): void {
      state.predefModals.push(options)
    },
    bootstrap (state: UIState): void {
      Services.EventBus.On('ui:modal:show', (data) => {
        state.modals.push(data.Data as Modal)
      })

      Services.EventBus.On('ui:modal:hide', (data) => {
        const idx = state.modals.findIndex((m) => m.Id === data.Data)

        if (idx !== -1) state.modals.splice(idx, 1)
      })

      Services.EventBus.On('ui:predefinedModal:show', (data) => {
        state.predefModals.push(data.Data as ModalOptions)
      })

      Services.EventBus.On('ui:predefinedModal:hide', (data) => {
        const idx = state.predefModals.findIndex((m) => m.Type === data.Data)

        if (idx !== -1) state.predefModals.splice(idx, 1)
      })
    }
  }
}

export { UIModule, UIState }

import { Util } from '../Util'
import { DropdownListItem } from '@/app_code/Forms'

class FormField {
  Id?: string = Util.GetId();
  Type: 'text' | 'email' | 'tel' | 'select' | 'textarea' | 'password' | 'date' | 'richtext' | 'url' | 'number' | 'userfile';
  Placeholder?: string;
  Label: string;
  Key: string;
  Value?: string;
  Accept?: string[];
  Items?: DropdownListItem[];
  OnGotValue: (() => void) | null;

  constructor (init: Partial<FormField>) {
    this.Type = init.Type ?? 'text'
    this.Placeholder = init.Placeholder
    this.Label = init.Label ?? ''
    this.Key = init.Key ?? Util.GetId()
    this.Value = init.Value ?? ''
    this.Accept = init.Accept ?? []
    this.Items = init.Items ?? undefined
    this.OnGotValue = init.OnGotValue ?? null
  }
}

export default FormField

import { LogLevel } from 'truemarket-modules/src/debug/Logger'
import { IConfig } from 'truemarket-modules/src/environment'
import HttpProviderType from 'truemarket-modules/src/services/httpclient/HttpProviderType'

export default {
  Http: {
    Provider: HttpProviderType.Fetch
  },
  API: {
    // Base: 'http://192.168.50.69:44384'
    // Base: 'https://localhost:44384'
    Base: 'https://api.truemarket.com.au'
  },
  Logging: {
    LogLevel: LogLevel.Debug
  },
  OAuth: {
    ConsumerId: '1fe32059-f6f3-4200-9942-f4fb325133b2'
  },
  Stripe: {
    PublishableKey: 'pk_live_0VYoqzKdm9GKgSxDKEC8w61F00wreNzw6A',
    // PublishableKey: 'pk_test_51PdVbRRtUPq96aSDtGEqqKe60fpDJMdCdn5xdWaEuJZcL7quJGHDWfPr7TCoBqFE5CDVXrAlXHfAWP1NrrrBQote00wRXoHNUF',
    ReturnUrl: 'https://console.truemarket.com.au/dashboard/admin/temp_valuations'
    // ReturnUrl: 'http://localhost:8080/dashboard/admin/temp_valuations'
  }
} as IConfig












import { Vue, Component, Prop } from 'vue-property-decorator'

import NavigationItemBadge from './NavigationItemBadge.vue'

@Component({
  components: {
    NavigationItemBadge
  }
})
export default class NavigationItem extends Vue {
  @Prop({ default: 'circle' })
  private readonly icon!: string;

  @Prop({ required: true })
  private readonly to!: string;

  @Prop({ default: false })
  private readonly isExternal!: boolean;

  @Prop()
  private readonly badge!: string;

  @Prop({ default: 'primary' })
  private readonly badgeType!: string;

  handleClicked () {
    if (this.isExternal) {
      window.open(this.to, '_blank')
    } else {
      this.$router.push(this.to)
    }
  }
}

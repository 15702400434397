













import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class FileUploadField extends Vue {
  selectFile () {
    const fi = document.createElement('input')

    fi.type = 'file'

    fi.style.display = 'none'

    document.body.appendChild(fi)

    fi.onchange = () => {
      if (!fi.files?.length) return

      const file = fi.files[0]

      Services.API.Files.Upload(file).then((file) => {
        this.$emit('input', file.FileId)
      })
    }

    fi.click()
  }
}








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CardFlag extends Vue {
  @Prop({ default: 'primary' })
  private readonly scheme!: string;
}






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Spinner extends Vue {
  @Prop({ default: 'var(--primary)' })
  private readonly colour!: string;

  @Prop({ default: 'var(--primary-trans)' })
  private readonly bg!: string;
}

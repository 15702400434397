




import { Vue, Component, Prop } from 'vue-property-decorator'
import feather from 'feather-icons'

@Component
export default class Icon extends Vue {
  @Prop({ required: true })
  private readonly icon!: string;

  mounted () {
    this.updateIcon()
  }

  updateIcon () {
    const elem = this.$refs.iconContainer as HTMLElement

    elem.innerHTML = feather.icons[this.icon].toSvg()
  }
}

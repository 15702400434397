import FormValue from './FormValue'

class FormDataset {
  Fields: FormValue[];

  constructor (init: Partial<FormDataset>) {
    this.Fields = init.Fields ?? []
  }

  BuildModel<TModel> (): TModel {
    const base: Record<string, string> = {}

    this.Fields.forEach((f) => {
      base[f.Key] = (f.Value || null) as string
    })

    return base as unknown as TModel
  }
}

export default FormDataset








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LinkBtn extends Vue {
  @Prop({ required: true })
  private readonly to!: string;

  @Prop({ default: false })
  private readonly external!: boolean;

  @Prop({ default: 'primary' })
  private readonly type!: string;

  @Prop({ default: false })
  private readonly invert!: boolean;

  @Prop({ default: false })
  private readonly loading!: boolean;

  @Prop()
  private readonly icon!: string;

  @Prop({ default: '0.75em 1.2em' })
  private readonly padding!: string;

  @Prop()
  private readonly bold!: boolean;

  @Prop()
  private readonly block!: boolean;

  @Prop({ default: 'n' })
  private readonly size!: string;

  handleClicked () {
    if (this.external) {
      window.open(this.to, '_blank')
    } else {
      this.$router.push(this.to)
    }
  }
}

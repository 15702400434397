import { render, staticRenderFns } from "./PrintPage.vue?vue&type=template&id=1c1e3313&scoped=true&"
import script from "./PrintPage.vue?vue&type=script&lang=ts&"
export * from "./PrintPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c1e3313",
  null
  
)

export default component.exports
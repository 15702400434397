










































































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Navigation extends Vue {
  private year = new Date().getFullYear().toString()
}












import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ContentSection extends Vue {
  @Prop()
  private readonly title!: string;
}

















import { Modal } from '@/app_code/Modals/Modal'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Form from './Forms/Form.vue'

@Component
export default class DynamicModal extends Vue {
  @Prop()
  private readonly modal!: Modal;

  mounted () {
    const formRef = this.$refs.form as Form

    this.modal.FormFieldValueGetter = () => {
      return formRef.GetValues()
    }
  }
}

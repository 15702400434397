





















import { Vue, Component } from 'vue-property-decorator'

import './assets/css/reset.css'
import './assets/css/base.css'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import ModalStack from './components/UI/ModalStack.vue'

@Component({
  components: {
    ModalStack
  }
})
export default class App extends Vue {

}

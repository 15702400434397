






import { Vue, Component, Prop } from 'vue-property-decorator'

import CKEditor from '@ckeditor/ckeditor5-vue2'

import InlineEditor from '@ckeditor/ckeditor5-build-inline'

@Component({
  components: {
    ckeditor: CKEditor.component
  }
})
export default class RichTextEditor extends Vue {
  editor = InlineEditor

  @Prop({ default: '' })
  private readonly content!: string;

  editorValue: string | null = null

  editorConfig = {
    image: {
      types: ['png', 'jpeg', 'jpg'],
      upload: {
      }
    }
  }

  mounted () {
    this.editorValue = this.content || ''
  }

  handleInput (val: string) {
    this.$emit('input', val)
  }
}





























import { Component, Vue, Prop } from 'vue-property-decorator'
import { DataTableDef } from '@/app_code/DataTable'

import DataRow from './DataRow.vue'

@Component({
  components: {
    DataRow
  }
})
export default class DataTable extends Vue {
  @Prop({ required: true })
  private readonly rows!: any[];

  @Prop({ required: true })
  private readonly def!: DataTableDef;
}










import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class NavigationGroup extends Vue {
  @Prop({ required: true })
  private readonly title!: string;
}
